import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/layout"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"

import SEO from "../../components/seo"

const Contact = ({ data, location }) => (
  <Layout>

    <SEO
      pagetitle="お問合せ"
      pagedesc="micro:bit（マイクロビット）を中心にプログラミング教育教材を提供いたします。"
      pagepath={location.pathname}
      pageimg={data.contact.childImageSharp.original.src}
      pageimgw={data.contact.childImageSharp.original.width}
      pageimgh={data.contact.childImageSharp.original.height}
    />

    <div className="eyecatch">
      <figure>
        <Img
          fluid={data.contact.childImageSharp.fluid}
          alt="micro:bit（マイクロビット）を中心にプログラミング教育教材を提供いたします。"
          loading="eager"
          durationFadeIn={100}
        />
      </figure>
    </div>

    <article className="content">
      <div className="container">

        <aside className="info">
          <div className="subtitle">
            <h1 className="bar"><FontAwesomeIcon icon={faQuestionCircle} />お問い合わせ</h1>
          </div>
        </aside>
        <div className="postbody">
            <p>
              お問い合わせ等は、下記のフォームよりご連絡をお願いいたします。
            </p>
        </div>
        <div className="contact">
          <form name="contactform" method="post" data-netlify="true" action="/jp/contact-thanks">
            <input type="hidden" name="form-name" value="contactform" />
            <div className="Form">
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  <span className="Form-Item-Label-Required">必須</span>組織名
                </p>
                <input type="text" className="Form-Item-Input" name="campany" id="campany" placeholder="例）学校法人プログラミング学園" />
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  <span className="Form-Item-Label-Required">必須</span>所属
                </p>
                <input type="text" className="Form-Item-Input" name="unit" id="unit" placeholder="例）プログラミング教育課" />
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  <span className="Form-Item-Label-Required">必須</span>氏名
                </p>
                <input type="text" className="Form-Item-Input" name="name" id="name" placeholder="例）山田太郎" />
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  <span className="Form-Item-Label-Required">必須</span>電話番号
                </p>
                <input type="text" className="Form-Item-Input" name="tel" id="tel" placeholder="例）000-0000-0000" />
              </div>
              <div className="Form-Item">
                <p className="Form-Item-Label">
                  <span className="Form-Item-Label-Required">必須</span>メールアドレス
                </p>
                <input type="text" className="Form-Item-Input" name="mail" id="mail" placeholder="例）example@proggakuen.com" />
              </div>
  
              <div className="Form-Item">
                <p className="Form-Item-Label isMsg">
                  <span className="Form-Item-Label-Required">必須</span>お問い合わせ内容
                </p>
                <textarea className="Form-Item-Textarea" defaultValue={""} name="message" id="message"></textarea>
              </div>
            </div>
            <button type="submit" className="Form-Btn">送信する</button>
          </form>
        </div>
      </div>
    </article>
  </Layout>
)

export const query = graphql`
  query {
    contact: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
        original {
          src
          height
          width
        }
      }
    }
  }
`
export default Contact
